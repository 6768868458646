<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">Agenda</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Agenda
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter mb-2">
              <div class="row">
                <div class="col-md-12 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item" v-if="criarPova">
                      <button class="btn btn-classic" @click="toSchedule()">
                        <i class="fa fa-plus" /> AGENDAR
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="page-content">
                <!-- <div id="calendar"></div> -->
                <div class="page-content__filter block__classic mb-2">
                  <FullCalendar
                    class="demo-app-calendar"
                    :options="calendarOptions"
                    :selectable="true"
                    @select="handleSelect"
                  >
                    <template v-slot:eventContent="arg">
                      <b>{{ arg.timeText }}</b>
                      <i>{{ arg.event.title }}</i>
                    </template>
                  </FullCalendar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import FullCalendar from "@fullcalendar/vue";
import ptbrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { constantes } from "@/constants";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    FullCalendar,
  },
  data() {
    return {
      showLeftMenu: true,
      criarPova: false,
      provas: [],
      calendarOptions: {
        locale: ptbrLocale,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
      },
    };
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    let roles = localStorage.getItem(constantes.STORAGE_UNIAVAN_USER_ROLES);
    let arrayRoles = roles.split(",");
    if (arrayRoles.includes("ROLE_PROFESSOR")) {
      this.criarPova = true;
    }
    this.loadData();
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      // let title = prompt("Please enter a new title for your event");
      // let calendarApi = selectInfo.view.calendar;
      // calendarApi.unselect(); // clear date selection
      // if (title) {
      //   calendarApi.addEvent({
      //     // id: createEventId(),
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay,
      //   });
      // }
    },

    toSchedule() {
      this.$router.push({
        name: "provas_create",
      });
    },
    handleEventClick(clickInfo) {
      console.log("here", clickInfo);
    },
    handleSelect(e) {
      console.log(e);
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    handleDateClick: function(info) {
      this.$router.push({ name: "provas_edit", params: { id: info.event.id } });
    },
    loadData(selectInfo) {
      this.$api
        .get("provas-criadas")
        .then((response) => {
          console.log(response);
          this.provas = response.data;
          let eventos = response.data.map((element) => {
            return {
              id: element.id,
              title: ' ' + element.titulo,
              start: element.dataHoraInicio,
            };
          });
          this.calendarOptions = {
            locale: ptbrLocale,
            events: eventos,
            eventClick: this.handleDateClick,
            plugins: [dayGridPlugin, interactionPlugin],
            initialView: "dayGridMonth",
            selectable: true,
          };
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>

<style>
.fc-daygrid-event{
  white-space: normal
}
</style>
